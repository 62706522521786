































































































































































import { Component, Mixins } from 'vue-property-decorator'
import TemperaturePresetsMenu from './TemperaturePresetsMenu.vue'
import InputTemperature from './InputTemperature.vue'
import StateMixin from '@/mixins/state'
import { Heater, Sensor } from '@/store/printer/types'

@Component({
  components: {
    TemperaturePresetsMenu,
    InputTemperature
  }
})
export default class TemperatureTargets extends Mixins(StateMixin) {
  get colors () {
    return this.$colorset.colorList
  }

  get extruder () {
    return this.$store.state.printer.printer.extruder
  }

  get heaters () {
    return this.$store.getters['printer/getHeaters']
  }

  get fans () {
    return this.$store.getters['printer/getOutputs'](['temperature_fan'])
  }

  get sensors () {
    return this.$store.getters['printer/getSensors']
  }

  get chartableSensors () {
    return this.$store.getters['printer/getChartableSensors']
  }

  get chartSelectedLegends () {
    return this.$store.getters['charts/getSelectedLegends']
  }

  get chartVisible () {
    return this.$store.state.config.uiSettings.general.chartVisible
  }

  setHeaterTargetTemp (heater: string, target: number) {
    this.sendGcode(`SET_HEATER_TEMPERATURE HEATER=${heater} TARGET=${target}`)
  }

  setFanTargetTemp (fan: string, target: number) {
    this.sendGcode(`SET_TEMPERATURE_FAN_TARGET TEMPERATURE_FAN=${fan} TARGET=${target}`)
  }

  getRateOfChange (item: Heater | Sensor) {
    const chartData = this.$store.getters['charts/getChartData']
    if (chartData.length < 2) {
      return '+0'
    }

    const [prev, curr] = chartData.slice(-2)
    const rateOfChange = Math.round((curr[item.name] - prev[item.name]) / (curr.date - prev.date) * 1000 * 10) / 10
    return `${rateOfChange < 0 ? '' : '+'}${rateOfChange.toFixed(1)}`
  }
}
